<template>
  <div v-show="showPage">
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="create-edit-form">
          <b-col cols="12">
            <h1
              class="custom-header-title"
            >
              Preview Annual Vendor Evaluation
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <section class="invoice-preview-wrapper">
      <b-row class="invoice-preview">
        <!-- Col: Left (Invoice Container) -->
        <b-col
          cols="12"
          xl="9"
          md="8"
        >
          <div>
            <b-card
              class="mb-0"
            >
              <b-row class="invoice-preview">
                <b-col
                  cols="12"
                  xl="4"
                  md="4"
                  class="text-left"
                >
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="link"
                    :disabled="currentFileIndex == 1"
                    @click="changeFileIndex(currentFileIndex - 1)"
                  >
                    <feather-icon
                      icon="ChevronLeftIcon"
                      class="pt-25"
                      size="18"
                    />
                    <span class="align-middle">Prev</span>
                  </b-button>
                </b-col>
                <b-col
                  cols="12"
                  xl="4"
                  md="4"
                  class="text-center"
                >
                  <b-dropdown
                    id="dropdown-1"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    split
                  >
                    <template #button-content>
                      <feather-icon
                        icon="FileIcon"
                        size="18"
                        class="align-middle mr-1"
                      />
                      <span class="mr-1">{{ currentFileIndex.toString().padStart(2, "0") }} / {{ evaluation.attachments ? ((evaluation.attachments.length + 1).toString().padStart(2, "0")) : '01' }}: {{ selectedFileName }}</span>
                    </template>
                    <b-dropdown-item @click="changeFileIndex(1)">
                      {{ evaluation.stringID }}
                    </b-dropdown-item>
                    <!-- <b-dropdown-item
                      v-for="(file, key) in evaluation.attachments"
                      :key="key"
                      @click="changeFileIndex(key + 2)"
                    >
                      {{ file ? file.name : '' }}
                    </b-dropdown-item> -->
                  </b-dropdown>
                  <p
                    v-show="currentFileIndex == 1"
                    class="mt-1"
                  >
                    Annual Vendor Evaluation
                  </p>
                  <!-- <p
                    v-show="currentFileIndex != 1"
                    class="mt-1"
                  >
                    {{ evaluation.attachments ? (evaluation.attachments[currentFileIndex - 2] ? evaluation.attachments[currentFileIndex - 2].description : '') : '' }}
                  </p> -->
                </b-col>
                <b-col
                  cols="12"
                  xl="4"
                  md="4"
                  class="text-right"
                >
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="link"
                    :disabled="currentFileIndex == (evaluation.attachments ? (evaluation.attachments.length + 1) : 1)"
                    @click="changeFileIndex(currentFileIndex + 1)"
                  >
                    <span class="align-middle">Next</span>
                    <feather-icon
                      icon="ChevronRightIcon"
                      class="pt-25"
                      size="18"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </div>
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <div v-show="showAttachment">
              <iframe
                v-show="showPDF"
                id="attachmentpdf"
                width="100%"
                height="1145"
              />
              <b-img
                v-show="!showPDF"
                :src="imageSrc"
                fluid-grow
                alt="Fluid-grow image"
              />
            </div>
            <div v-show="!showAttachment">
              <iframe
                id="docpdf"
                width="100%"
                height="1145"
              />
            </div>
          </b-card>
        </b-col>

        <!-- Right Col: Card -->
        <b-col
          cols="12"
          md="4"
          xl="3"
          class="invoice-actions"
        >
          <b-card v-if="user._id == (evaluation.createdBy ? evaluation.createdBy._id : '') && !$route.query.type">
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <span class="step-class">Approval Required</span>
                Ready to Submit?
              </h3>
            </template>

            <b-card-text class="mb-0">
              <span>
                If the information on this Annual Vendor Evaluation is accurate, please proceed to confirm to e-sign.
              </span>
              <br>
              <span>It will then be sent to the relevant parties for e-signing after.</span>
            </b-card-text>

            <b-form-checkbox
              v-model="secondAgree"
              value="accepted"
              unchecked-value=""
              class="custom-control-primary"
            >
              I confirm that all information is accurate on this form.
            </b-form-checkbox>

            <!-- Button: Send Invoice -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mb-75"
              block
              :disabled="secondAgree == ''"
              @click="submitForApproval()"
            >
              <feather-icon
                icon="FeatherIcon"
                class="mr-50"
                size="16"
              />
              <span class="align-middle">Sign and Submit</span>
            </b-button>

            <!-- Button: DOwnload -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="flat-primary"
              class="mb-75"
              block
              :to="{ name: 'purchasing-vendors-evaluation-detail', params: { id: $route.params.id } }"
            >
              Back to Detail
            </b-button>
          </b-card>
          <b-card v-else>
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <span class="step-class">{{ approverHeader }}</span>
                {{ approverTitle }}
              </h3>
            </template>
            <b-card-text
              v-if="$route.query.type && $route.query.type == (evaluation.signatories ? (evaluation.signatories.length - 1) : 0)"
              class="mb-0"
            >
              <span class="text-bold-black">You are now reviewing this Annual Vendor Evaluation (AVE) form as the signatory for "{{ approverType }}".</span>
              <br>
              <span>
                If the information on this Annual Vendor Evaluation (AVE) is accurate, e-sign this form to approve this AVE.
              </span>
            </b-card-text>
            <b-card-text
              v-else
              class="mb-0"
            >
              <span class="text-bold-black">You are now reviewing this Annual Vendor Evaluation (AVE) form as the signatory for "{{ approverType }}".</span>
              <br>
              <span>If the information on this Annual Vendor Evaluation (AVE) form is accurate, e-sign this form to approve this AVE and it will be sent to the next assigned signatory ({{ nextApproverName }}) for signing.</span>
            </b-card-text>

            <b-form-checkbox
              v-model="secondAgree"
              value="accepted"
              unchecked-value=""
              class="custom-control-primary"
            >
              I confirm that all information is accurate on this AVE.
            </b-form-checkbox>

            <!-- Button: Send Invoice -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mb-75"
              block
              :disabled="secondAgree == ''"
              @click="approveAVE()"
            >
              <feather-icon
                icon="FeatherIcon"
                class="mr-50"
                size="16"
              />
              <span class="align-middle">{{ approverButtonText }}</span>
            </b-button>

            <!-- Button: DOwnload -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="flat-primary"
              class="mb-75"
              block
              :to="{ name: 'purchasing-vendors-evaluation-detail', params: { id: $route.params.id } }"
            >
              Back to Detail
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BCard, BRow, BButton, BCol, BImg, BCardText, VBTooltip, BFormCheckbox, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
// import html2pdf from 'html2pdf.js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BImg,
    BCardText,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  // props: {
  //   evaluation: {
  //     type: Object,
  //     required: true,
  //   },
  //   evaluation: {
  //     type: Object,
  //     required: true,
  //   },
  // },
  data() {
    return {
      canViewThisAction,
      showPage: false,
      user: store.state.auth.userData,
      evaluation: {},
      evaluationItems: [],
      approverType: '',
      approverHeader: '',
      approverButtonText: '',
      approverTitle: '',
      nextApproverName: '',
      selectedFileName: '',
      currentFileIndex: 1,
      chunkEvaluationItems: [],
      chunksOfEvaluationItems: [],
      pdfTotalPage: 0,
      elHeight: 0,
      gapHeight: 0,
      secondAgree: '',
      pdfDataJS: '',
      imageSrc: '',
      showPDF: true,
      showAttachment: false,
      showFirst: true,
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/frontend/logo-nirvana-memorial-garden.86fe2ff5.svg'),
    }
  },
  mounted() {
    this.$http.get(`purchase/vendor-evaluations/${this.$route.params.id}/show`)
      .then(response => {
        this.evaluation = response.data.evaluation || {}
        this.evaluationItems = response.data.evaluationItems || {}
        this.selectedFileName = this.evaluation.stringID
        if (this.$route.query.type) {
          let { type } = this.$route.query
          // eslint-disable-next-line radix
          type = parseInt(type)

          if (this.evaluation.signatories[type].signed === true || this.resolveSignValidation(type) || this.evaluation.signatories[type].user._id !== this.user._id) {
            this.$router.push({ name: 'page-not-found' })
          }
          if (type === (this.evaluation.signatories.length - 1)) {
            this.approverType = this.evaluation.signatories[type].type
            this.approverHeader = 'Signature Required'
            this.approverButtonText = 'Sign and Approve AVE'
            this.approverTitle = 'Approve This AVE?'
            this.nextApproverName = ''
          } else {
            this.approverType = this.evaluation.signatories[type].type
            this.approverHeader = 'Signature Required'
            this.approverButtonText = 'Sign and Confirm AVE'
            this.approverTitle = 'Confirm This AVE?'
            this.nextApproverName = this.evaluation.signatories[type + 1].user.name
          }
        }
        this.showPage = true
        this.enablePreview()
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  methods: {
    resolveSignValidation(key) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < key; i++) {
        if (this.evaluation.signatories[i].signed === false) return true
      }
      return false
    },
    changeFileIndex(key) {
      this.currentFileIndex = key
      if (key === 1) {
        this.selectedFileName = this.evaluation.stringID
        // eslint-disable-next-line func-names
        // pdfViewerFrame.onload = () => {
        //   pdfViewerFrame.contentWindow.PDFViewerApplication.open(this.pdfDataJS)
        // }
        // this.showFirst = false
        // const pdfViewerFrame = document.getElementById('docpdf2')
        // pdfViewerFrame.setAttribute('src', `/lib/pdfjs/web/viewer.html?file=${this.pdfDataJS}`)
        this.showAttachment = false
      } else {
        this.selectedFileName = this.evaluation.attachments[key - 2].name
        if (this.evaluation.attachments[key - 2].type.includes('pdf')) {
          // eslint-disable-next-line func-names
          // pdfViewerFrame.onload = () => {
          //   pdfViewerFrame.contentWindow.PDFViewerApplication.open(this.evaluation.attachments[key - 2].data)
          // }
          const pdfViewerFrame2 = document.getElementById('attachmentpdf')
          pdfViewerFrame2.setAttribute('src', `/lib/pdfjs/web/viewer.html?file=${this.evaluation.attachments[key - 2].data}`)
          this.showPDF = true
        } else {
          this.imageSrc = this.evaluation.attachments[key - 2].data
          this.showPDF = false
        }
        this.showAttachment = true
      }
    },
    resolveItemCounter(page, index) {
      let count = 0
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < (page - 1); i++) {
        count += this.chunksOfEvaluationItems[i].length
      }

      count += index + 1

      return count
    },
    base64ToUint8Array(base64) {
      const raw = atob(base64)
      const uint8Array = new Uint8Array(raw.length)
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < raw.length; i++) {
        uint8Array[i] = raw.charCodeAt(i)
      }
      return uint8Array
    },
    async enablePreview() {
      const formData = new FormData()
      formData.append('url', `${window.location.origin}/vendors/evaluation/verify/preview`)
      // formData.append('url', `${window.location.origin}/purchase-request/download/po/65599c5db44ced55532da059`)
      formData.append('landscape', true)
      const localStorageObj = {
        veData: this.evaluationItems,
        evaluation: this.evaluation,
      }
      formData.append('localStorageObj', JSON.stringify(localStorageObj))
      // formData.append('aveForm', JSON.stringify(this.aveForm))
      // formData.append('signatoryAssigns', JSON.stringify(signatoryArray))
      // formData.append('entityString', this.entityString)

      this.$http.post('purchase/vendor/evaluation/pdf/download', formData, {
        headers: { Accept: 'application/pdf' },
        responseType: 'arraybuffer',
      })
        .then(response => {
          const uint8View = new Uint8Array(response.data)
          const pdfViewerFrame = document.getElementById('docpdf')
          // eslint-disable-next-line func-names
          pdfViewerFrame.onload = () => {
            pdfViewerFrame.contentWindow.PDFViewerApplication.open(uint8View)
          }
          pdfViewerFrame.setAttribute('src', '/lib/pdfjs/web/viewer.html?file=')
          this.currentFileIndex = 1
          // this.selectedFileName = this.companyName
          this.showAttachment = false
          window.scrollTo(0, 0)
          this.evaluationPreview = true
          store.commit('appConfig/UPDATE_LOADER', false)
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    async calculateTotalPage() {
      await this.$nextTick()
      this.pdfTotalPage = 0
      this.chunkEvaluationItems = []
      this.chunksOfEvaluationItems = []
      const logoHeight = document.getElementById('main-header').offsetHeight
      const footerHeight = document.getElementById('footer-div').offsetHeight
      let contentHeight = document.getElementById('content-div').offsetHeight
      let totalItems = this.evaluationItems.length
      // let i = 0
      let sliceStart = 0
      let sliceEnd = 10
      while (totalItems > 0) {
        contentHeight = 1080 - (logoHeight + footerHeight)
        // if (i === 0) {
        //   contentHeight = 1000 - (logoHeight + footerHeight)
        // } else {
        //   contentHeight = 1000 - (logoHeight + footerHeight)
        // }
        // console.log(contentHeight)
        this.chunkEvaluationItems = this.evaluationItems.slice(sliceStart, sliceEnd)
        // this.$nextTick(() => {
        //   console.log(document.getElementById('content-div').offsetHeight)
        // })
        // eslint-disable-next-line no-await-in-loop
        await this.$nextTick()
        let tableHeight = document.getElementById('content-div').offsetHeight
        // console.log(tableHeight)
        if (tableHeight > contentHeight) {
          contentHeight += footerHeight
          // contentHeight += vendorAndDeliveryDetailHeight
        }
        // console.log(tableHeight, contentHeight)
        while (tableHeight > contentHeight) {
          sliceEnd -= 1
          this.chunkEvaluationItems = this.evaluationItems.slice(sliceStart, sliceEnd)
          // console.log(sliceStart, sliceEnd)
          // eslint-disable-next-line no-await-in-loop
          await this.$nextTick()
          tableHeight = document.getElementById('content-div').offsetHeight
          // console.log(tableHeight, contentHeight)
        }
        // console.log(tableHeight)
        // console.log(sliceEnd - sliceStart)
        // console.log(this.chunkEvaluationItems.length)
        this.chunksOfEvaluationItems.push(this.chunkEvaluationItems)
        this.pdfTotalPage += 1
        // i += 1
        sliceStart = sliceEnd
        sliceEnd += 10
        totalItems -= this.chunkEvaluationItems.length
      }
      let remainingHeight = 0
      const dHeight = 1080
      contentHeight = document.getElementById('content-div').offsetHeight
      remainingHeight = dHeight - (logoHeight + footerHeight + contentHeight)
      if (remainingHeight > 0) {
        this.gapHeight = remainingHeight
      }
      if (contentHeight >= (dHeight - (logoHeight + footerHeight))) {
        this.pdfTotalPage += 1
        this.gapHeight = dHeight - (logoHeight + footerHeight)
      }
    },
    closeDescriptionModal() {
      this.$root.$emit('bv::hide::modal', 'modal-attachment-description', '')
    },
    updateDescription(key) {
      this.attachmentKey = key
      this.description = this.evaluation.attachments[key].description
      this.$root.$emit('bv::show::modal', 'modal-attachment-description', '')
    },
    resolveAcronymName(name) {
      return name.split(' ').map(item => item.charAt(0)).join('').toUpperCase()
    },
    saveDescription() {
      const url = this.evaluation.attachments[this.attachmentKey].data
      this.$http
        .post('purchase/purchase-request/save-description', { url, description: this.description, evaluation: this.$route.params.id })
        .then(response => {
          this.$emit('update:evaluation', response.data.data)
          this.closeDescriptionModal()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    submitForApproval() {
      this.$swal({
        title: `Submit this Annual Vendor Evaluation ${this.evaluation.period}?`,
        html: `You are proceeding to sign this evaluation. This Annual Vendor Evaluation will then be sent to ${this.evaluation.signatories[1] ? this.evaluation.signatories[1].user.name : ''} for signing.`,
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/fly.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Proceed!',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()
            formData.append('url', window.location.origin)

            this.$http.post(`purchase/vendor-evaluations/${this.$route.params.id}/requester/submit`, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(() => {
                this.$swal({
                  title: 'Submitted for Approval',
                  html: `${this.evaluation.stringID} will now be sent to the next assigned signatory (${this.evaluation.signatories[1] ? this.evaluation.signatories[1].user.name : ''}) for signing.`,
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/success.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                  .then(confirm => {
                    if (confirm.value) {
                      this.$router.push({ name: 'purchasing-vendors-evaluation-detail', params: { id: this.$route.params.id } })
                    }
                  })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },

    approveAVE() {
      let index = this.$route.query.type
      // eslint-disable-next-line radix
      index = parseInt(index)
      const id = this.$route.query.user
      let firstSwalMessage = ''
      let secondSwalMessage = ''
      let secondSwalTitle = 'AVE Form Signed'
      if ((this.evaluation.signatories.length - 1) === index) {
        firstSwalMessage = 'You are proceeding to sign this evaluation. This Annual Vendor Evaluation will then be marked as complete and closed.'
        secondSwalTitle = `Annual Vendor Evaluation ${this.evaluation.period} Signed`
        secondSwalMessage = `Annual Vendor Evaluation ${this.evaluation.period} has been signed and closed.<br>All relevant parties will receive a notification.`
      } else {
        firstSwalMessage = `You are proceeding to sign this evaluation. This Annual Vendor Evaluation will then be ready for ${this.evaluation.signatories[index + 1] ? this.evaluation.signatories[index + 1].user.name : ''} for signing.`
        secondSwalMessage = `${this.evaluation.stringID} will now be sent to the next assigned signatory (${this.evaluation.signatories[index + 1] ? this.evaluation.signatories[index + 1].user.name : ''}) for signing.`
      }
      // this.previewPage = true
      this.$swal({
        title: `Sign this Annual Vendor Evaluation ${this.evaluation.period}?`,
        html: firstSwalMessage,
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/fly.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Proceed!',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()
            formData.append('url', window.location.origin)
            formData.append('id', id)
            formData.append('index', index)
            formData.append('signatoryRequired', this.evaluation.signatoryOption)

            this.$http.post(`purchase/vendor-evaluations/approve-ave/${this.$route.params.id}`, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(() => {
                this.$swal({
                  title: secondSwalTitle,
                  html: secondSwalMessage,
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/success.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                  .then(confirm => {
                    if (confirm.value) {
                      this.$router.push({ name: 'purchasing-vendors-evaluation-detail', params: { id: this.$route.params.id } })
                    }
                  })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
